import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

const HtmlHeader = props => {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta charSet='utf-8' />
        <meta
          name='facebook-domain-verification'
          content='zmvcsaz19bw9b3we15tfkwtvuzxnxl'
        />
      </Helmet>
    </>
  )
}

export default HtmlHeader
