import React, { useState, useEffect } from 'react'
import { Language } from 'react-ionicons'
import { delay } from './functions'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { createHook } from 'react-sweet-state'

import LanguageSwitch from '../components/LanguageSwitch'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const ToggleNavigation = props => {
  const [state, actions] = useLanguage()

  const data = useStaticQuery(graphql`
    query {
      allStrapiTestimonialsSection {
        edges {
          node {
            locale
            id
            title
          }
        }
      }
      allStrapiProcessSection {
        edges {
          node {
            locale
            id
            title
          }
        }
      }
      allStrapiFaqSection {
        edges {
          node {
            locale
            id
            title
          }
        }
      }
      allStrapiTermsPage {
        edges {
          node {
            locale
            menuTitle
          }
        }
      }
      allStrapiLocales {
        edges {
          node {
            locale
            home
          }
        }
      }
    }
  `)

  const dataLocalized = {
    ...data.allStrapiTermsPage.edges.filter(
      item => item.node.locale === state.language
    )[0].node,
    ...data.allStrapiLocales.edges.filter(
      item => item.node.locale === state.language
    )[0].node
  }
  const { menuTitle: termsMenuTitle, home } = dataLocalized

  const { visible, transitionSpeed } = props
  const [toggleNavigationOpacity, setToggleNavigationOpacity] = useState(0)
  const [toggleNavigationDisplay, setToggleNavigationDisplay] = useState('none')

  useEffect(async () => {
    setToggleNavigationDisplay('grid')
    await delay(transitionSpeed * 1000)

    setToggleNavigationOpacity(visible ? 1 : 0)

    if (!visible) {
      await delay(transitionSpeed * 1000)
      setToggleNavigationDisplay('none')
    }
  }, [visible])

  const renderPageMenu = () => {
    const menuData = [
      data.allStrapiTestimonialsSection,
      data.allStrapiProcessSection,
      data.allStrapiFaqSection
    ].map(
      menuItem =>
        menuItem.edges.filter(item => item.node.locale === state.language)[0]
    )

    const menuItems = menuData.map(menuItem => (
      <li className='menu-item'>
        <Link
          to={'#' + menuItem.node.id}
          className='menu-link'
          onClick={props.onSiteMenuItemClick}
        >
          {menuItem.node.title}
        </Link>
      </li>
    ))

    return <ul className='page-menu'>{menuItems}</ul>
  }

  return (
    <div
      className='toggle-navigation'
      style={{
        opacity: toggleNavigationOpacity,
        display: toggleNavigationDisplay,
        transition: `opacity ${transitionSpeed}s ease-in-out`
      }}
    >
      {renderPageMenu()}
      <div className='wrapper'>
        <ul className='site-menu'>
          <li className='menu-item'>
            <Link
              to={state.language === 'en' ? '/' : '/de'}
              className='menu-link'
            >
              {home}
            </Link>
          </li>
          <li className='menu-item'>
            <Link
              to={state.language === 'en' ? '/terms' : '/de/terms'}
              className='menu-link'
            >
              {termsMenuTitle}
            </Link>
          </li>
          <li className='menu-item'>
            <LanguageSwitch className='menu-link -has-icon' />
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ToggleNavigation
