import React, { useState, useEffect } from 'react'

const usePageIsAtTheTop = () => {
  const [pageIsAtTheTop, setPageIsAtTheTop] = useState(true)

  const detectPageIsAtTheTop = () => {
    return window.scrollY < 100
  }

  const updatePageIsAtTheTop = () => {
    setPageIsAtTheTop(detectPageIsAtTheTop())
  }

  useEffect(() => {
    window.addEventListener('scroll', updatePageIsAtTheTop)
    updatePageIsAtTheTop()
    return () => window.removeEventListener('scroll', updatePageIsAtTheTop)
  }, [])

  return [pageIsAtTheTop, setPageIsAtTheTop]
}

export default usePageIsAtTheTop
