import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { createHook } from 'react-sweet-state'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const CtaButton = props => {
  const [state, actions] = useLanguage()

  const data = useStaticQuery(graphql`
    query {
      allStrapiLocales {
        edges {
          node {
            headerCtaButtonText
            locale
          }
        }
      }
      allStrapiConfig {
        edges {
          node {
            registrationFormUrl
            locale
          }
        }
      }
    }
  `)
  const dataLocalized = {
    ...data.allStrapiLocales.edges.filter(
      item => item.node.locale === state.language
    )[0].node,
    ...data.allStrapiConfig.edges.filter(
      item => item.node.locale === state.language
    )[0].node
  }
  const { headerCtaButtonText, registrationFormUrl } = dataLocalized

  const getReferer = () => {
    return new URLSearchParams(window.location.search).get("referer")
  }

  const trackAndRedirect = (url) => {
    if(window.fbq) {
      window.fbq("trackCustom", "Call to Action")
    }

    const referer = getReferer()

    window.location.replace(referer === null ? url : url + "?referer=" + referer)
  }

  return (
    <button className={props.className} onClick={() => trackAndRedirect(registrationFormUrl)}>
      {props.label ? props.label : headerCtaButtonText}
    </button>
  )
}

export default CtaButton
