import { useState, useEffect } from 'react'

const delay = (t, v) => {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, v), t)
  })
}

const rotate = (index, max, incrementEnabled = true) => {
  if (incrementEnabled) {
    index++
    if (index === max) {
      index = 0
    }
  } else {
    index--
    if (index < 0) {
      index = max - 1
    }
  }
  return index
}

export { delay, rotate }
