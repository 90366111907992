import React, { useState } from 'react'
import { createHook } from 'react-sweet-state'
import { LogoWhatsapp, Mail, Language } from 'react-ionicons'
import { Link, useStaticQuery, graphql } from 'gatsby'

import LanguageSwitch from '../components/LanguageSwitch'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const MainFooter = () => {
  const [state, actions] = useLanguage()
  const data = useStaticQuery(graphql`
    query {
      allStrapiLocales {
        edges {
          node {
            locale
            home
          }
        }
      }
      strapiContactInfo {
        phonenumber
        emailaddress
      }
      allStrapiTermsPage {
        edges {
          node {
            locale
            menuTitle
          }
        }
      }
    }
  `)

  const dataLocalized = {
    ...data.allStrapiLocales.edges.filter(
      item => item.node.locale === state.language
    )[0].node,
    ...data.allStrapiTermsPage.edges.filter(
      item => item.node.locale === state.language
    )[0].node
  }

  const { home, menuTitle: termsMenuTitle } = dataLocalized

  const { phonenumber, emailaddress } = data.strapiContactInfo

  return (
    <footer className='main-footer'>
      <div className='wrapper'>
        <ul className='footer-menu'>
          <li className='menu-item'>
            <Link
              to={state.language === 'en' ? '/' : '/de'}
              className='link -light'
            >
              {home}
            </Link>
          </li>
          <li className='menu-item'>
            <Link
              to={state.language === 'en' ? '/terms' : '/de/terms'}
              className='link -light'
            >
              {termsMenuTitle}
            </Link>
          </li>
          <li className='menu-item -has-icon'>
            <LanguageSwitch className='link -light' iconColor='white' />
          </li>
        </ul>
        <div className='footer-logo'>
          <img src='/logo-light.svg' alt='' className='image' />
        </div>
        <ul className='contact-list -light'>
          <li className='contact-item'>
            <LogoWhatsapp color='white' />
            {phonenumber}
          </li>
          <li className='contact-item'>
            <Mail color='white' />
            {emailaddress}
          </li>
        </ul>
        <p className='footer-copyright'>© 2021 Pflege Place</p>
      </div>
    </footer>
  )
}

export default MainFooter
