import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { createHook } from 'react-sweet-state'
import '../styles/index.scss'

import MainFooter from '../components/MainFooter'
import MainHeader from '../components/MainHeader'
import HtmlHeader from '../components/HtmlHeader'
import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const Layout = props => {
  const [state, actions] = useLanguage()
  const data = useStaticQuery(graphql`
    query {
      allStrapiConfig {
        edges {
          node {
            locale
            htmlBaseTitle
          }
        }
      }
    }
  `)

  const dataLocalized = data.allStrapiConfig.edges.filter(
    item => item.node.locale === state.language
  )[0]
  const { htmlBaseTitle } = dataLocalized.node

  return (
    <>
      <HtmlHeader title={htmlBaseTitle} />
      <main>
        <MainHeader />
        {props.children}
        <MainFooter />
      </main>
    </>
  )
}

export default Layout
