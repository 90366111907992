import React, { useState } from 'react'
import { createHook } from 'react-sweet-state'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Language } from 'react-ionicons'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const LanguageSwitch = props => {
  const [state, actions] = useLanguage()

  const data = useStaticQuery(graphql`
    query {
      allStrapiLocales {
        edges {
          node {
            german
            english
            locale
          }
        }
      }
    }
  `)
  const dataLocalized = data.allStrapiLocales.edges.filter(
    item => item.node.locale === state.language
  )[0]
  const { german, english, home, headerCtaButtonText } = dataLocalized.node

  return (
    <Link
      className={props.className}
      to={state.language === 'en' ? '/de' : '/'}
    >
      <Language color={props.iconColor} />
      {state.language === 'en' ? german : english}
    </Link>
  )
}

export default LanguageSwitch
