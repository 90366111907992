import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'

import Hamburger from 'hamburger-react'
import { spacing } from './config.js'

import ToggleNavigation from '../components/ToggleNavigation'
import usePageIsAtTheTop from './usePageIsAtTheTop'
import LanguageSwitch from '../components/LanguageSwitch'
import CtaButton from '../components/CtaButton.js'
import { createHook } from 'react-sweet-state'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const MainHeader = props => {
  const [toggleNavigationVisible, setToggleNavigationVisible] = useState(false)
  const [pageIsAtTheTop, setPageIsAtTheTop] = usePageIsAtTheTop()
  const [state, actions] = useLanguage()

  const handleHamburgerToggle = toggled => {
    setToggleNavigationVisible(toggled)
  }

  return (
    <div
      className={`main-header _fixed-full${
        !pageIsAtTheTop ? ' -after-scroll' : ''
      }`}
    >
      <div className='outer-wrapper'>
        <Link to='/' className='logo'>
          <img className='image' src='/logo.svg' alt='Logo' />
        </Link>
        <div className='inner-wrapper'>
          <div className='group'>
            <a href="https://register.pflegeplace.com/admin">{state.language === 'en' ? 'for Agencies' : 'für Agenturen'}</a>
            <LanguageSwitch className='link -has-icon' />
            <CtaButton className='button' />
          </div>
          <div className='hamburger-icon'>
            <Hamburger
              onToggle={toggled => handleHamburgerToggle(toggled)}
              toggled={toggleNavigationVisible}
            />
          </div>
        </div>
      </div>
      <ToggleNavigation
        visible={toggleNavigationVisible}
        transitionSpeed={0.2}
        onSiteMenuItemClick={() => setToggleNavigationVisible(false)}
      />
    </div>
  )
}

export default MainHeader
