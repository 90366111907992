const spacing = (index, pixelEnabled = true) => {
  const spacingScale = [
    4,
    8,
    12,
    16,
    24,
    32,
    48,
    64,
    80,
    96,
    128,
    192,
    256,
    384,
    512,
    640,
    768,
    1024,
    1280,
    1440
  ]
  return pixelEnabled ? spacingScale[index - 1] + 'px' : spacingScale[index - 1]
}

const breakPoints = {
  galaxyFold: 280,
  xs: spacing(14, false),
  sm: spacing(16, false),
  md: spacing(17, false),
  lg: spacing(18, false),
  xl: spacing(19, false)
}

export { spacing, breakPoints }
