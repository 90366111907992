import { createStore, createSubscriber, createHook } from 'react-sweet-state'

const Store = createStore({
  initialState: {
    language: 'en'
  },
  actions: {
    set: languageKey => ({ setState, getState }) => {
      setState({
        language: languageKey
      })
    }
  },
  name: 'language'
})

export default Store
